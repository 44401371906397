import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
   headerTitle: string,
   uppercase?: boolean,
   numberSubColumns?: number
}

const HeaderColumn = (props: Props) => {
   const { t } = useTranslation();
   const { projectSelected } = useProjects();
   const { validateReferenceType } = useReferenceType(projectSelected);
   const { headerTitle, uppercase = false, numberSubColumns } = props;

   return (
      <th
         key={headerTitle}
         colSpan={numberSubColumns}
         className={`text-dark-80 font-sfpro-medium font-12px align-top pt-0 pr-3 pl-3 bg-white ${uppercase ? 'text-uppercase' : 'text-capitalize'}`}
         style={{ minWidth: "200px", maxWidth: "200px", position: 'sticky', left: "0px", zIndex: 10 }}
      >
         <div className='bg-white-100 text-dark-100' style={{ width: "200px", paddingLeft: "16px", marginLeft: "-16px" }}>
            {t(`${headerTitle}${validateReferenceType ? '-material' : ''}`)}
         </div>
      </th>
   )

}

// <HeaderColumn headerTitle={} uppercase />

export default HeaderColumn;
