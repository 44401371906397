import { useProjects } from "contexts/projectsContext";
import { useEffect, useState } from "react";
import Dashboard from "services/http/Dashboards";
import { FetchData, RankingCEI, SummaryCEI } from "../data.interface";

const initSummaryData: SummaryCEI = {
    mci_a: '0',
    mci_a_score: 'low',
    recycled: '0',
    TU_waste: 0,
    utility: '0'
}
const initRankingData: RankingCEI = {
    best: [],
    worst: []
}

const dashboard = new Dashboard();
const fetchData = (id: string): Promise<FetchData> => {
    return Promise.all([
        dashboard.ceiSummary(id),
        dashboard.rankingPerformanceCircularity(id)
    ])
    .then(([summaryData, rankingData]) => {
        return {
            summaryData: summaryData.data,
            rankingData: rankingData.data
        }
    })
    .catch(() => {
        return {
            summaryData: initSummaryData,
            rankingData: initRankingData
        }
    });
}

export const useDashboardData = () => {
    const [loading, setLoading] = useState(true);
    const { projectSelected } = useProjects();
    const [summary, setSummary] = useState<SummaryCEI>(initSummaryData);
    const [ranking, setRanking] = useState<RankingCEI>(initRankingData);


    useEffect(() => {
        let isMounted = true;

        if ( projectSelected ) {
            const id = projectSelected.id;
            setLoading(true);
            fetchData(id)
            .then(({ summaryData, rankingData }) => {
                if ( isMounted ) {
                    setSummary(summaryData);
                    setRanking(rankingData);
                }
            })
            .finally(() => setLoading(false));
        }

        return () => {
			isMounted = false;
		}
    }, [projectSelected])


    return {
        summary,
        ranking,
        loading
    }
}