/* eslint-disable */
import { useProjects } from "contexts/projectsContext";
import { useTable } from "contexts/tableContext";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FiltersSelected from "../FiltersSelected/Index";
import SelectorProject from 'UI/molecules/SelectorProject';
import useOutsideClick from "hooks/useOutsideClick.hook";

interface FiltersSectionInterface {
    showSelectFilter?: boolean
    isLoading: boolean
    wPath: string
    generalProduct: string
    product: string
    scope: string
}
const FiltersSection = ({showSelectFilter, isLoading, wPath, generalProduct, product, scope}: FiltersSectionInterface) => {
    const { setOpenFiltersModal, filtersApplied, cleanFilters, cleanGroupFilters, setSectionFilterSelectedToOpen } = useTable();
    const { filtersCategoriesByProject, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
    const [ show, setShow ] = useState<boolean>(false);
    const [underlineClearFilters, setUnderlineClearFilters] = React.useState<boolean>(false)
    const { t } = useTranslation();

    const handleOpenFiltersModal = () => {
        if( openModalSaveChanges.customView ){
            setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
        }else{
            setOpenFiltersModal(true);
            setSectionFilterSelectedToOpen('');
        }
    }

    const relationFilterCategories:any = {
        articleCategoryId: 'articleCategories',
        materialCategoryId: 'materialCategories',
        materialId: 'materials',
        originId: 'origins',
        rawMaterialCategoryId: 'rawMaterialCategories',
        rawMaterialProcessId: 'rawMaterialProcesses',
        transportCategoryName: 'transportCategories',
        wetProcessId: 'wetProcesses'
    }

    const itemsSelected:any = {}
    for (const filterIdGroup in filtersApplied) {
        if (filtersApplied[filterIdGroup].length > 0) {
            itemsSelected[filterIdGroup] = []
            const namesArr = filtersApplied[filterIdGroup].map((item:string) => {
                return filtersCategoriesByProject[relationFilterCategories[filterIdGroup]].find((g:any) => g.id ? g.id === item : g.name === item).name
            })
            namesArr.forEach((name:string) => itemsSelected[filterIdGroup].push(name))
        }
    }

    const isItemsSelected = ():boolean => Object.keys(itemsSelected).length > 0


    const FiltersSelectedGroups = () => (
        <div className="d-flex flex-row align-items-center mx-2">
            {Object.keys(itemsSelected).map((groupId:string) => <FiltersSelected filters={itemsSelected[groupId]} groupId={groupId} handleCleanFilter={() => cleanGroupFilters(groupId)} />)}
        </div>
    )

    const clearAllEnabled = Object.values(filtersApplied).find((values:any) => values.length > 0 ) !== undefined;


	return (
        <div className="d-flex flex-row align-items-center" style={{
            opacity: isLoading ? 0.5 : 1
        }}>
            <span className="font-12px font-sfpro-regular text-dark-100 mr-1">{t('forms-filterby')}:</span>
                <SelectorProject isLoading={isLoading} />
                {(!isLoading && isItemsSelected()) && <FiltersSelectedGroups />}
            {
                (['plm', 'lca', 'cei', 'sustainabilityindex'].includes(product) && scope === 'dashboard') && (
                    <SelectedTag isLoading={isLoading} wPath={wPath} generalProduct={generalProduct} product={product} />
                )
            }

            {
                showSelectFilter &&
                <div
                    className="font-12px ml-2"
                    onMouseEnter={() => !isLoading && setShow(true)}
                    onMouseLeave={() => !isLoading && setShow(false)}
                >
                    <span className={`icon-icon-add font-8px ${ show ? "text-dark-100" : "text-dark-60"}`} />
                    <span
                        className={`font-12px ${ show ? "text-dark-100" : "text-dark-60"} ${!isLoading ? 'cursor-pointer' : ''} ml-1`}
                        onClick={handleOpenFiltersModal}
                        style={{
                            textDecoration: show ? "underline" : ""
                        }}
                    >
                        {t("common-add-new-filters")}
                    </span>
                </div>
            }
            {
                clearAllEnabled &&
                <span
                    onMouseEnter={() => setUnderlineClearFilters(true)}
                    onMouseLeave={() => setUnderlineClearFilters(false)}
                    className="font-12px font-sfpro-regular text-dark-60 hover-dark-100 ml-2 cursor-pointer"
                    onClick={cleanFilters}
                    style={{
                        textDecoration: underlineClearFilters ? "underline" : ""
                    }}
                >{t("common-clean-all")}</span>}
       </div>
	);
};

const COLLECT_SAMPLE = 'collection-sampler'
const TOTAL_UNITS = 'total-units'

type Option = typeof COLLECT_SAMPLE | typeof TOTAL_UNITS | ''
const options: Option[] = [TOTAL_UNITS, COLLECT_SAMPLE]

const TAG_BY_VIEW: Record<string, Option> = {
    'plm': COLLECT_SAMPLE,
    'lca': TOTAL_UNITS,
    'sustainabilityindex': TOTAL_UNITS,
    'cei': TOTAL_UNITS,
    'articles': COLLECT_SAMPLE,
}

const SelectedTag = ({wPath, generalProduct, product, isLoading}: {wPath: string, generalProduct: string, product: string, isLoading: boolean}) => {
	const { t } = useTranslation();
    const disabledOption = useRef<Option>('')

	const [optionSelected, setOptionSelected] = useState<Option>('');
	const [isShow, setIsShow] = useState<boolean>(false);
	const [hoverSelectOption, setHoverSelectOption] = useState(false);
	const ref = useRef<HTMLHeadingElement>(null)

	const handleOptionSelected = (item: Option) => {
		setOptionSelected(item);
    }

	const projectClick = (option: Option) => {
        handleOptionSelected(option);
        setIsShow(!isShow);
    }

	const handleOpenDropdown = () => {
		!isLoading && setIsShow(!isShow);
    }

    useEffect(() => {
        const tagByView = generalProduct === 'articles' ? TAG_BY_VIEW[generalProduct] : TAG_BY_VIEW[product]
        setOptionSelected(tagByView)
        disabledOption.current = options.find((option: Option) => option !== tagByView) || ''
    }, [wPath])

	useOutsideClick(ref, () => { setIsShow(false) });

	return (
		<div
            className="d-flex project-selector flex-column align-items-start ml-1"
            ref={ref}
            onClick={handleOpenDropdown}
        >
                <div
                    className={`position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-1 pl-3 pr-1 ${hoverSelectOption ? "border-dark-60 bg-transparent" : isShow ? "border-dark-100 bg-transparent" : "border-gray-3 bg-gray-01"}`}
                    style={{ borderRadius: "30px" }}
                    onMouseEnter={() => !isLoading && setHoverSelectOption(true)}
                    onMouseLeave={() => !isLoading && setHoverSelectOption(false)}
                >
                    <span className={`${hoverSelectOption ? "text-dark-60" : "text-dark-100"} font-12px font-sfpro-medium text-overflow-ellipsis overflow-hidden`}>
                        {t(optionSelected)}
                    </span>
                    <div className={`d-flex align-items-center justify-content-center`}
                    style={{
                        height: "32px",
                        width: "32px",
                    }}>
                        <span
                            onClick={handleOpenDropdown}
                            className={`icon-icon-chevron-down ${hoverSelectOption ? "text-dark-60" : "text-dark-100"} font-30px`}
                            style={{
                                transition: "all 0.5s ease",
                                transformOrigin: "50% 50%",
                                transform: (isShow) ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                        />
                    </div>
                    {isShow &&
                    <OptionDropdown optionSelected={optionSelected} options={options} disabledOption={disabledOption.current}  handleClick={projectClick} />}
            </div>
        </div>
	)
}

export function OptionDropdown({options, optionSelected, disabledOption, handleClick}: {options: Option[], optionSelected: Option, disabledOption: Option, handleClick: (option: Option) => void}) {
	const { t } = useTranslation()
	const equalty = (option: Option) => optionSelected === option
	return (
		<div
			className="dropdown-menu show position-absolute"
			style={{ right: -130, left: "auto" }}
		>
			{options.map((option: Option) => (
				<button
					key={option.trim()}
					onClick={() => handleClick(option)}
					className={`selected-project font-sfpro-medium d-flex align-items-center dropdown-item ${!equalty(option) ? 'cursor-not-allow text-dark-40 poiner-event--normal' : ''}`}
                    disabled={option === disabledOption}
                    style={{
                        width: '190px',
                    }}
				>
				    <span className="icon-icon-tick mr-2 text-dark-100 font-18px"
                        style={{
                            visibility: equalty(option) ? 'visible' : 'hidden'
                        }}
                    ></span>
					<div
						className={`${
							equalty(option)
								? "font-sfpro-medium"
								: "font-sfpro-regular"
						}
                        text-overflow-ellipsis overflow-hidden`}
					>
						{t(option)}
					</div>
				</button>
			))}
		</div>
	);
}

export default FiltersSection;
