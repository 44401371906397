import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SaveIcon } from 'UI/atoms/SaveIcon';
import SearchBar from 'UI/atoms/Searchbar';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import CreateViewHeader from 'UI/molecules/CreateViewHeader';
import SelectorViews from 'UI/molecules/SelectorViews';

import Toggle from 'components/Common/toggle/toggle';

interface Props {
	isLoading: boolean;
}

const COLUMNS = 'columns';
const DISABLED_OPTIONS = [''];

const handleDisableOptions = (id: string) => DISABLED_OPTIONS.includes(id);

const TableHeaderOptions = (props: Props) => {
	const {
		setOpenColumnSelectorModal,
		customView,
		selectedView,
		areThereViews,
		setModalViewActionSelected,
		enableBenchmarkData,
		setEnableBenchmarkData
	} = useTable();
	const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const h = useHistory();
	const path = h.location.pathname;
	const product = path.split('/')[1];
	const scope = path.split('/')[2];
	const { t } = useTranslation();

	const { isLoading } = props;

	useEffect(()=>{
		if( openModalSaveChanges.open ) setModalViewActionSelected("saveWarning")
	},[openModalSaveChanges])

	const handleToggleColumns = () => {
		setOpenColumnSelectorModal(true);
	};

	const toggleColumnsBtn = {
		id: 'toggleColumns',
		icon: 'columns',
		action: handleToggleColumns,
	};

	const IconAction = ({ btn, iconActive, setIconActive }: any) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);
		const btnId = `table-btn-${btn.id}`;

		const handleIconActive = () =>{
			if (handleDisableOptions(btn.id)) return;

			if( btn.icon === "download" && openModalSaveChanges.customView ){
				setOpenModalSaveChanges({
					...openModalSaveChanges,
					open: true
				})
				return
			}

			setIconActive(btn.id);
			btn.action();
		}

		return (
			<>
				<div
					id={btnId}
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
					className={`
						d-flex align-items-center justify-content-center cursor-pointer rounded-08 text-dark-60 text-dark-100-hover
						${(iconActive == btn.id && !enableBenchmarkData) ? "bg-gray-01 text-dark-100" : ""}
						${ handleDisableOptions(btn.id) ? "d-flex align-items-center justify-content-center rounded-08 block-dashboard" : "" }
					`}
					onClick={handleIconActive}
				>
					<span
						className={`icon-icon-${btn.icon} font-20px`}
						style={{ margin: '6px' }}
					></span>
				</div>
				<SimpleTooltip
					tooltipId={btnId}
					tooltipOpen={isTooltipOpen}
					text={t(`tooltip-btn-${btn.id}`)}
				/>
			</>
		);
	};
	const metricActions = scope === 'cei' ? [] : [toggleColumnsBtn]

	const iconActionsByPath: any = {
		metrics: metricActions,
		evaluation: [],
		diagnosis: [toggleColumnsBtn],
		digitization: [],
		traceability: []
	};

	const [iconActive, setIconActive ] = useState("")
	const pathWithTableMenu = ['digitization'];

	const [openTooltipIndustryStandard, setOpenTooltipIndustryStandard] = useState(false);
	const tooltipIndustryStandardId = 'switch-lca-benchmark';

	const handleOpenTooltipIndustryStandard = (isOpen: boolean) => () => setOpenTooltipIndustryStandard(isOpen);

	useEffect(()=>{
		setTimeout(()=>{
			setIconActive("")
		},500)
	},[iconActive])

	return (
		<div className="d-flex flex-row align-items-center justify-content-between border-bottom py-1 mb-3 bg-white-100" style={{ minHeight: '65px' }}>
			<div className="d-flex flex-row align-items-center">
				<div style={{ minWidth: '250px', maxWidth: '250px' }}>
					{selectedView && !isLoading && <SelectorViews />}
					{areThereViews === 'no' && !isLoading && !selectedView && (
						<CreateViewHeader />
					)}
				</div>
				{
					(scope === "lca" && selectedView) &&
					<div className="row w-100 d-flex align-items-center mb-1 mt-1 mx-0">
						<p
							id={tooltipIndustryStandardId}
							className="font-sfpro-regular font-12px text-dark-100 mt-2 pl-2"
							onMouseEnter={handleOpenTooltipIndustryStandard(true)}
							onMouseLeave={handleOpenTooltipIndustryStandard(false)}
						>
							{t('switch-lca-benchmark')}
						</p>
						<div className="col-md-2">
							<Toggle
								onChange={(value: boolean) => setEnableBenchmarkData(value)}
								checked={enableBenchmarkData}
								disabled={isLoading}
								styles={{ marginLeft: '5px' }}
							/>
						</div>
						<SimpleTooltip
							tooltipId={tooltipIndustryStandardId}
							tooltipOpen={openTooltipIndustryStandard}
							text={t('switch-lca-benchmark-tooltip')}
							styles={{textAlign: 'left'}}
						/>
					</div>
				}
			</div>

			{selectedView && (
				<div className="d-flex flex-row align-items-center justify-content-between pr-3">
					<SearchBar />
					{iconActionsByPath[product] &&
						iconActionsByPath[product].map((btn: any) => (
							<IconAction btn={btn} iconActive={iconActive} setIconActive={setIconActive} />
						))}
					{customView && <SaveIcon />}
				</div>
			)}
		</div>
	);
};

export default TableHeaderOptions;
