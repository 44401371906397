import { useTranslation } from "react-i18next"

import { formatNumberThreeDigits, formatNumberNoDecimals, formatNumber, numberWithDots } from "utils/utils"
import { NO_DECIMAL_VALUES } from "../constants"

const MATERIAL_LIST = [ 'BCI', 'PET', 'PP', 'PE', 'HDPE', 'LDPE', 'POM', 'PMMA', 'PU',
                        'PVC', 'PVA', 'TPR', 'TPU', 'ABS', 'CA', 'CMiA', 'EVA', 'PA',
                        'PAN', 'PC', 'PES', 'PLA', 'PS', 'PTFE', 'PTT', 'PUD', 'SBR', 'TPE', 'CMT']

const useRow = () => {
    const {t} = useTranslation()

    const standarizateName = (name: string) => {
        if (name) {
            const specialWords = name.split(" ").filter(word => MATERIAL_LIST.includes(word))
            let nameLowerCase = name.toLowerCase()
            if (specialWords && specialWords.length > 0) {
                specialWords.forEach(element => {
                    nameLowerCase = nameLowerCase.replace(element.toLowerCase(), element)
                });
            }

            return capitalizeWord(nameLowerCase)
        }
        return ""
    }

    // Determinate progress bar color
	const determinateProgressBarColor = (value:number) => {
		if (value <= 20) return '#E1EEFE';
		else if (value > 20 && value <= 30) return '#C9DBF8';
		else if (value > 30 && value <= 40) return '#9DBEF4';
		else if (value > 40 && value <= 50) return '#729FEE';
		else if (value > 50 && value <= 60) return '#497ACF';
		else if (value > 60 && value <= 70) return '#2754B1';
		else if (value > 70 && value <= 80) return '#10399E';
		else return '#001D6C';
	}

    const handleCompositionMaterial = (values: any[]) => {
        const results =
            values?.length > 0
            ?   values.filter((item:any) => item !== null).map((item: any) =>
                    `${formatNumberNoDecimals((parseFloat(item.amount) * 100))}% ${item.name}`
                )
            : ['No materials']

        return results.join(' + ')
    }

    // Determinate completion category
	const determinateCompletionCategory = (score: string, value:number) => {
		let resultScore = '--';
		let resultValue = '--%';

		if (score) resultScore = score.toUpperCase()
		if (value) resultValue = `${formatNumberNoDecimals(value * 100)}%`;

		return `${resultScore} | ${resultValue}`
	}

    const joinItemsArray = (list:any[] = [], key: string, attribute?: string) => {

        const materials = list.map(item => {
            if (attribute) {
                return item[key][attribute]
            }
            if (item[key]) {
                return item[key]
            }
        })
        return materials.join(", ")
    }

    const handleValueTypeText = (metricName: string, metricValue: any, datas: any) => {
        if (metricName === 'weight') return formatNumberThreeDigits(metricValue)
        if (metricName === 'amount') return numberWithDots(metricValue)
        if (metricName === 'category' && metricValue) return metricValue.name
        if (metricName === 'categoryId') return standarizateWarehouse(standarizateName(joinItemsArray(metricValue, 'name')))
        if (
            metricName === 'material_process_finishing_supplier_names' ||
			metricName === 'material_process_extraction_supplier_names' ||
			metricName === 'material_process_rmatProcessing_supplier_names' ||
			metricName === 'material_process_matProcessing_supplier_name'
        ) {
            if (metricValue && metricValue.length > 0) {
                if (metricValue.every((item: any) => item === null)) return t('not-applicable')

                if (metricName === 'material_process_finishing_supplier_names') {
                    return convertToString(metricValue)
                }
                if (metricValue.length > 0) return standarizateName(convertToString(metricValue))
            }

            return t('not-applicable')
        }

        if (NO_DECIMAL_VALUES.includes(metricName)) return formatNumberNoDecimals(metricValue)

        if (metricName === 'supplier_transport_category') {
            return evaluateTransport(metricValue)
        }

        if (metricName === 'supplier_tiers' && metricValue?.length > 0) return convertToString(metricValue)


        if (metricName === 'material_certificate_names' || metricName === 'supplier_certificate_names') {
            if (metricValue.length > 0) return convertToString(metricValue)

            return t('no-certificates')
        }

        if (metricName === 'material_process_extraction_relativeAmounts') {
            return handleCompositionMaterial(datas['material_process_extraction_relativeAmounts'])
        }

        return metricValue
    }

    const convertToString = (data:any) => {
        const filterData = data.filter((item: any) => item !== null)
        if (filterData.every(evaluateUnknown)) return "Unknown"

        const result = filterData.map((item:any) => item?.toLowerCase().includes("unknown") ? 'Unknown' : item)

        if (result.every(evaluateUnknown)) return "Unknown"

        return result.join(", ")
    }

    const handleColor = (values: any, datas: any) => {
        if (Array.isArray(values)) {
            const filterArray =  values.filter((item: any) => item !== null)
            if (filterArray.length === 0) return true

            if (
                datas['material_process_extraction_shortNames'] &&
                datas['material_process_extraction_shortNames'].filter((item:any) => item !== null).length === 0
            ) return true

        }

        const valueType = typeof values
        if (valueType === 'string') {
            if (values === "-") return true
            return evaluateUnknown(values)
        }

        if (valueType === 'object' || valueType === 'number') return false
    }

    const evaluateUnknown = (item: any) => (item === "unknown" || item === "Unknown")

    const standarizateWarehouse = (name: string) => name.toLowerCase().includes('warehouse') ? t('modal-accordeon-warehouse') : name

    const evaluateTransport = (value: any) => value && value !== '-' ? value : t('no-transport')

    const blockByPlan = (plan: any) => false;

    const capitalizeWord = (word: string) => `${word[0].toUpperCase()}${word.slice(1, word.length)}`

    return {
        standarizateName,
        determinateProgressBarColor,
        determinateCompletionCategory,
        joinItemsArray,
        handleValueTypeText,
        handleColor,
        handleCompositionMaterial,
        convertToString,
        standarizateWarehouse,
        evaluateTransport,
        blockByPlan,
        capitalizeWord
    }
}

export default useRow