import React from 'react';
import './ArticleImg.scss';
import ImageExist from "services/http/imgExist"
import { useAccount } from "contexts/accountContext"

interface Props {
   imgUrl: any,
   hover?: boolean,
   styles?: string | undefined,
}
const bcomeLogo = '/img/Logo BCome.jpg';

export const ArticleImgSmall = (props:Props) => {
   const { account } = useAccount();

   const { imgUrl } = props;
   const [articleImg, setArticleImg] = React.useState(imgUrl)

   const accountLogo = () =>{
      account?.logoUrl
         ? setArticleImg(account.logoUrl)
         : setArticleImg(bcomeLogo)
   }

   const detectImg = async() => {
      const response = await ImageExist(imgUrl);
      response ? setArticleImg(imgUrl) : accountLogo()
   }

   React.useEffect(()=>{
      if (articleImg === null || articleImg === undefined) {
         accountLogo()
      }else{
         detectImg();
      }

   },[imgUrl, articleImg])

   // ROW ARTICLE IMAGE
   return (
      <div className="article-image-wrap" style={{ minWidth: '28px', width: '28px' }}>
         <div className="article-image d-flex align-items-center justify-content-center position-relative"
            style={{ height: '42px', width: '28px' }}>
            <img
               className="position-absolute"
               src={articleImg}
               onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=bcomeLogo;
                }}
            />
            <div className="icon-eye-container" style={{ zIndex: 100}}>
               <span className="icon-icon-eye d-none d-lg-block" style={{color: 'transparent', width:"15px"}} />
            </div>
         </div>
      </div>
   )
}

export const ArticleImgDetails = (props:Props) => {
   const { account } = useAccount();

   const { imgUrl, styles } = props;
   const [articleImg, setArticleImg] = React.useState(imgUrl)

   const accountLogo = () =>{
      account?.logoUrl
         ? setArticleImg(account.logoUrl)
         : setArticleImg(bcomeLogo)
   }

   const detectImg = async() => {
      const response = await ImageExist(imgUrl);
      response ? setArticleImg(imgUrl) : accountLogo()
   }

   React.useEffect(()=>{
      if (articleImg === null || articleImg === undefined) {
         accountLogo()
      }else{
         detectImg()
      }
   },[imgUrl, articleImg])

   // ROW ARTICLE IMAGE
   return (
      <div className={`article-image-wrap ${styles ? styles : ''}`} style={{ minHeight: '55px', height: '55px' }}>
         <div className="article-image" style={{ height: '55px', width: '50px' }}>
            <img
               className="position-absolute"
               src={articleImg}
               onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=bcomeLogo;
                }}
            />
         </div>
      </div>
   )
}

//ONE PAGE ARTICLE IMAGE

export const ArticleImgSmallMedium = (props:Props) => {
   const { account } = useAccount();

   const { imgUrl, styles } = props;
   const [articleImg, setArticleImg] = React.useState(imgUrl)

   const accountLogo = () =>{
      account?.logoUrl
         ? setArticleImg(account.logoUrl)
         : setArticleImg(bcomeLogo)
   }

   const detectImg = async() => {
      const response = await ImageExist(imgUrl);
      response ? setArticleImg(imgUrl) : accountLogo()
   }

   React.useEffect(()=>{
      if (articleImg === null || articleImg === undefined) {
         accountLogo()
      }else{
         detectImg()
      }
   },[articleImg, imgUrl])

   // ROW ARTICLE IMAGE
   return (
      <div className={`article-image-wrap ${styles ? styles : ''}`} style={{ minHeight: '69px', height: '69px' }}>
         <div className="article-image" style={{ height: '69px', width: '63px' }}>
            <img
               className="position-absolute"
               src={articleImg}
               onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=bcomeLogo;
                }}
            />
         </div>
      </div>
   )
}

export const ArticleImgMedium = (props:Props) => {
   const { account } = useAccount();

   const { imgUrl, styles } = props;
   const [articleImg, setArticleImg] = React.useState(imgUrl)

   const accountLogo = () =>{
      account?.logoUrl
         ? setArticleImg(account.logoUrl)
         : setArticleImg(bcomeLogo)
   }

   const detectImg = async() => {
      const response = await ImageExist(imgUrl);
      response ? setArticleImg(imgUrl) : accountLogo()
   }

   React.useEffect(()=>{
      if (articleImg === null || articleImg === undefined) {
         accountLogo()
      }else{
         detectImg()
      }
   },[imgUrl, articleImg])

   // ROW ARTICLE IMAGE
   return (
      <div className={`article-image-wrap ${styles ? styles : ''}`} style={{ minHeight: '215px', height: '215px' }}>
         <div className="article-image" style={{ height: '215px', width: '170px' }}>
            <img
               className="position-absolute"
               src={articleImg}
               onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=bcomeLogo;
                }}
            />
         </div>
      </div>
   )
}



export const ArticleImgSmartLabel = (props:Props) => {
   const { account } = useAccount();

   const { imgUrl } = props;
   const [articleImg, setArticleImg] = React.useState(imgUrl)

   const accountLogo = () =>{
      account?.logoUrl
         ? setArticleImg(account.logoUrl)
         : setArticleImg(bcomeLogo)
   }

   const detectImg = async() => {
      const response = await ImageExist(imgUrl);
      response ? setArticleImg(imgUrl) : accountLogo()
   }

   React.useEffect(()=>{
      if (articleImg === null || articleImg === undefined) {
         accountLogo()
      }else{
         detectImg()
      }
   },[imgUrl, articleImg])

   // ROW ARTICLE IMAGE
   return (
      <div className="smartLab-image-wrap mt-2">
         <div className="smartLab-image">
            <img
               className="position-absolute"
               src={articleImg}
               onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=bcomeLogo;
                }}
                style={{
                  width: '100%',
                  height: '100%'
                }}
            />
         </div>
      </div>
   )
}




