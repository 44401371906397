import { useTable } from 'contexts/tableContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./CreateViewHeader.scss"



const CreateViewHeader = () => {
    const { t } = useTranslation();
    const {setModalViewActionSelected} = useTable()

    return (
        <div className="create-view-header font-sfpro-regular d-flex align-items-center cursor-pointer nav-link" onClick={() => setModalViewActionSelected("createView")}>
            <span className="text text-dark-100 font-sfpro-regular font-20px">
                {t("createView")}
            </span>
            <span className='icon icon-icon-button-filters hover-dark-100 font-16px mt-1 mx-2 text-dark-60'></span>
        </div>
    );
}


export default CreateViewHeader;
