/* eslint-disable */
import { useTable } from 'contexts/tableContext';
import React from 'react';

interface IFiltersSelectedProps {
	handleCleanFilter: () => void;
	filters: string[];
	groupId: any;
}

const FiltersSelected = (props: IFiltersSelectedProps) => {
	const { handleCleanFilter, filters, groupId } = props;
	const { setOpenFiltersModal, setSectionFilterSelectedToOpen } = useTable();

	const handleOpenFiltersModal = () => {
		setOpenFiltersModal(true);
		setSectionFilterSelectedToOpen(groupId);
	};

	return (
		<div className="position-relative">
			<div
				className="d-flex flex-row align-items-center justify-content-between bg-gray-2 bg-white-active-100 bg-white-hover-100 border-dark-100 text-overflow-ellipsis overflow-hidden border rounded-20 pr-2 pl-2 mr-1 ml-1"
				style={{ minWidth: '98px', maxWidth: '98px', height: '42px' }}
			>
				<div
					className="text-overflow-ellipsis overflow-hidden cursor-pointer"
					onClick={handleOpenFiltersModal}
				>
					{filters.map((f: string) => (
						<span className="font-12px font-sfpro-regular text-dark-100 hover-dark-80 ml-1">
							{f}
							{filters.length > 1 && ','}
						</span>
					))}
				</div>
				<span
					className="icon-icon-close font-8px text-dark-100 hover-dark-80 ml-2 cursor-pointer"
					onClick={handleCleanFilter}
				/>
			</div>
			{filters.length > 1 && (
				<div
					className="icon-icon-bg-notification position-absolute font-14px position-relative"
					style={{ bottom: '21px', right: '29px' }}
				>
					<span
						className="font-sfpro-medium text-white-100 position-absolute"
						style={{
							fontSize: '7px',
							right: `${filters.length > 9 ? '3px' : '4.5px'}`,
							top: '3px',
						}}
					>
						{filters.length}
					</span>
				</div>
			)}
		</div>
	);
};

export default FiltersSelected;
