import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Elements/Input/Input';
import { Input as Checkbox, FormFeedback } from 'reactstrap';

import { SpinnerLoader } from 'components/Common/Loader/Loader';
import Modal from 'components/Common/Modal/Modal';
import { Alert } from 'utils/Alerts';

import RegexLibrary from '../../../utils/Regex';
import HttpAuth from '../../../services/http/Auth';

import StorageAuth from '../../../services/storage/Auth';

import Notification from '../../../components/Elements/Notification';
import ErrorException from '../../../services/exception/ErrorException';
import PermissionProvider from 'providers/permissionProvider';
import { useHistory } from 'react-router-dom';
import PATHS from 'permissions/roles/AppPaths';
import RightsReserved from 'UI/molecules/RightsReserved';
import HandlePasswordVisibility, { isEmptyValue, VisibilityPassword } from 'UI/atoms/PasswordVisibility';
import "../../Template/Modals/ModalBase/ModalBase.scss"

const LoginForm: FunctionComponent = (props: any) => {
	const { t }: any = useTranslation();
	const history = useHistory();

	const [emailValue, setEmailValue] = useState('');
	const [emailIsValid, setEmailIsValid] = useState(true);
	const [emailFeedbackMessage, setEmailFeedbackMessage] = useState('');
	const [resetEmailValue, setResetEmailValue] = useState('');
	const [resetEmailIsValid, setResetEmailIsValid] = useState(true);
	const [resetEmailFeedbackMessage, setResetEmailFeedbackMessage] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [passwordIsValid, setPasswordIsValid] = useState(true);
	const [passwordFeedbackMessage, setPasswordFeedbackMessage] = useState('');
	const [rememberMeValue, setRememberMeValue] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [showModal, setShowModal] = useState('');

	const [inputTypePassword, setInputTypePassword] = useState<VisibilityPassword>('password')

	const [permissionAccess, setPermissionAccess]: any = useContext(PermissionProvider);
	const h = history || props.history;



	const validateResetEmail = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!resetEmailValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		} else if (!RegexLibrary.email.test(resetEmailValue)) {
			isValid = false;
			feedbackMessage = t('common-emailFormat');
		}

		setResetEmailIsValid(isValid);
		setResetEmailFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const validateEmail = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!emailValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		} else if (!RegexLibrary.email.test(emailValue)) {
			isValid = false;
			feedbackMessage = t('common-emailFormat');
		}

		setEmailIsValid(isValid);
		setEmailFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const validatePassword = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!passwordValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		}
		setPasswordIsValid(isValid);
		setPasswordFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateEmail() || !validatePassword()) return;

		setLoading(true);

		try {
			const response = await new HttpAuth().login(emailValue, passwordValue);
			if (response) {
				setLoading(false);
				const storage = new StorageAuth();
				storage.saveSession(
					{
						accessToken: response.accessToken,
						refreshToken: response.refreshToken,
						rememberMe: rememberMeValue,
					}
				);
				const user = storage.decodeAccessToken(response.accessToken);
				setPermissionAccess(user.roles);

				//PENDO TRACKING
				if (!emailValue.includes('@bcome')) {
					window.pendo.initialize({
						visitor: {
							id: user.userId,
							email: emailValue
						},

						account: {
							id: user.accountId,
							name: user.accountName,
							email: emailValue
						}
					});
				}

				h.push(PATHS.home);
			}
		} catch (err) {
			console.log("ERR", err);
			// TODO!!!
			// Notification.displayException(err);
			const error = await new ErrorException(t('errors-invalid-login'));
			Notification.displayException(error);
			setLoading(false);
			return;
		}
	};

	const handleModal = async () => {
		if (!validateResetEmail()) return;

		setLoadingModal(true);
		try {
			const resetPassword = await new HttpAuth().resetPassword(resetEmailValue);
			if (resetPassword) {
				Alert(
					t('alert-congratulations'),
					t('alert-success-resetPassword'),
					'success',
					false
				);
			}
		} catch (err) {
			setResetEmailFeedbackMessage(err);
		}
		setLoadingModal(false);
		setShowModal('');
	};

	return (
		<>
			<div id="login-form">
				<div id="form-container">
					<div className="col-12 pt-5">
						<div className="d-flex justify-content-center pb-5" >
							<img className="" style={{ width: '160px' }} src="../img/bcome-logo1.svg" alt="logo" />
						</div>
					</div>
					<div className="col-12">
						<form onSubmit={handleSubmit}>
							<div className="w-100 mb-3">
								<Input
									type="text"
									placeholder="E-mail"
									autoFocus={true}
									invalid={!emailIsValid}
									value={emailValue}
									className="login-input"
									onChange={(e: any) => setEmailValue(e.target.value)}
									labelText="Email"
									labelClassName="login-input-title"
									icon="iconClassName atSign"
								/>
							</div>
							<div className="w-100 d-flex align-items-center">
								<Input
									type={inputTypePassword}
									placeholder="Password"
									invalid={!passwordIsValid}
									value={passwordValue}
									className="login-input w-100"
									onChange={(e: any) => setPasswordValue(e.target.value)}
									labelText="Password"
									labelClassName="login-input-title"
									icon="iconClassName lock"
									divClassName='w-100 d-flex'
								/>
								<HandlePasswordVisibility inputType={inputTypePassword} setInputType={setInputTypePassword} isEmpty={isEmptyValue(passwordValue)} />
							</div>
							<div className="col-12 forgot-password mb-5 mt-2"
								style={{ marginLeft: '-10px' }}>
								<div onClick={() => setShowModal('modal-forgot-password')}>
									{t('login-forgotPassword')}
								</div>
							</div>
							<div className="col-12 remember-me-container mb-3 ">
								<div className="checkbox c-checkbox font-sfpro-regular">
									<label>
										<Checkbox
											checked={rememberMeValue}
											onChange={(e: any) =>
												setRememberMeValue(e.target.checked)
											}
											type="checkbox"
										/>
										<span className="fa fa-check"
											style={{ width: '17px', height: '17px' }}></span>&nbsp;
										{t('login-remember')}
									</label>
								</div>
							</div>
							<div className="col-12 mb-2">
								<button
									type="submit"
									style={{ height: '48px' }}
									className="btn btn-primary d-flex justify-content-center w-100 align-items-center"
									disabled={loading}>
									<span className='font-sfpro-medium'>{t('login-toBcome')}</span>
									{loading && <SpinnerLoader />}
								</button>
							</div>
						</form>
					</div>
				</div>
				<RightsReserved />
			</div>

			<Modal
				modal={showModal}
				setModal={setShowModal}
				className="page-selector-modal"
				scrollable
				createText={t('common-send')}
				handleSubmit={handleModal}
				disableButton={false}
				title={t('login-resetPassword')}
				size="md"
				isLoading={loadingModal}
				style={{ height: '180px', minHeight: '0px' }}
				modalName="modal-forgot-password">
				<div className="modal-container align-content-start" >
					<div className="col-12">
						<div className='font-14px text-black-100 font-sfpro-regular mt-3 mb-4'>{t('login-resetPassword-info')}</div>
						<Input
							type="text"
							placeholder="yourname@example.com"
							autoFocus={true}
							invalid={!resetEmailIsValid}
							value={resetEmailValue}
							className="login-input"
							onChange={(e: any) => setResetEmailValue(e.target.value)}
							labelText={t('common-email')}
							labelClassName="login-input-title"
							icon="iconClassName atSign"
						/>
						{resetEmailFeedbackMessage && (
							<FormFeedback>{resetEmailFeedbackMessage}</FormFeedback>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LoginForm;
