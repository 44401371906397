import { useValidateSmartLabel } from "hooks/useValidateSmartLabel.hook";
import React, { useContext, useEffect, useState } from "react";
import AccountHttp from 'services/http/Account';
import UserHttp from 'services/http/User';
import StorageAuth from 'services/storage/Auth';


const Account = new AccountHttp();
const User = new UserHttp();

export const AccountContext = React.createContext<any>(null);
export const useAccount = () => useContext(AccountContext);

export const AccountContextProvider = ({ children }: any): any => {
    const { validate } = useValidateSmartLabel();
    const [account, setAccount]: any = useState();
	const [user, setUser]: any = useState();
    const [loading, setLoading] = useState(true);

    const getAccount = () => {
        const accountId = new StorageAuth().getSessionUser()?.accountId;
        if (accountId) {
            setLoading(true);
            const request = Account.get(accountId);
            request.then(res => {
                const accountInfo = res.data;
                setAccount(accountInfo);
            })
            .finally(() => setLoading(false));
        }
    }

    const loadUser = () => {
        const userId = new StorageAuth().getSessionUser()?.userId;
        if (userId) {
            const request = User.userById(userId);
            request.then(res => {
                const user = res.data;
                setUser(user);
            });
        }
    }

    useEffect(() => {
        validate(() => {
            getAccount();
            loadUser();
        })
    }, []);

    const value = { account, setAccount, user, setUser, loading };

    return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}
