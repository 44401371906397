import { EIndicators, EStages, ETraceabilityScopes } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const traceabilityArticleSchema: ITableColumns = {
    // Article scope
    articleSummary: {
        indicator: EIndicators.articleSummary,
        stage: EStages.articleSummary,
        label: 'articleSummary',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.articleSummary)
    },
    completion: {
        indicator: EIndicators.completion,
        stage: EStages.completion,
        label: 'completion',
        color: '#',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.completion)
    },
    // Suppliers scope
    supplierDescription: {
        indicator: EIndicators.supplierDescription,
        stage: EStages.supplierDescription,
        label: 'supplierDescription',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.supplierDescription)
    },
    logistics: {
        indicator: EIndicators.logistics,
        stage: EStages.logistics,
        label: 'logistics',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.logistics)
    },
    certificateLists: {
        indicator: EIndicators.certificateLists,
        stage: EStages.certificateLists,
        label: 'certificateLists',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.certificateLists)
    },
    bestPractices: {
        indicator: EIndicators.bestPractices,
        stage: EStages.bestPractices,
        label: 'bestPractices',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.bestPractices)
    },
    // Materials scope
    materialDescription: {
        indicator: EIndicators.materialDescription,
        stage: EStages.materialDescription,
        label: 'materialDescription',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.materialDescription)
    },
    materialSupply: {
        indicator: EIndicators.materialSupply,
        stage: EStages.materialSupply,
        label: 'materialSupply',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.materialSupply)
    },
    material_certificate_names: {
        indicator: EIndicators.material_certificate_names,
        stage: EStages.material_certificate_names,
        label: 'material_certificate_names',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.material_certificate_names)
    },
    // Logistic scope
    logisticDescription: {
        indicator: EIndicators.logisticDescription,
        stage: EStages.logisticDescription,
        label: 'logisticDescription',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.logisticDescription)
    },
    packagingDescription: {
        indicator: EIndicators.packagingDescription,
        stage: EStages.packagingDescription,
        label: 'packagingDescription',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.packagingDescription)
    }

}

export default traceabilityArticleSchema