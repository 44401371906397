import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpStorageI18n from './services/storage/Language';

import translation_en from './i18n/en/translations.json';
import translation_es from './i18n/es/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translation_en },
    es: { translation: translation_es }
  },
  lng: HttpStorageI18n.getCurrentLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  ns: ['translations', 'countries']
});

export default i18n;
