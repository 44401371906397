import React from 'react';
import { useTranslation } from "react-i18next";
import { formatNumberNoDecimals } from "utils/utils";
import { SmartLabelTooltip } from 'UI/Pages/Smartlabel/SmartLabelTooltip';

interface IProgressBarScoreIcon {
    color: string,
    value: string | undefined,
    label: string | undefined,
    score: number | undefined,
    icon: string
}

export const ProgressBarScoreIcon = (props: IProgressBarScoreIcon) => {
    const { t } = useTranslation();
    const [indicator, setIndicator] = React.useState("");
    const [metric, setMetric] = React.useState("");
    const {color, value, label, score, icon } = props;
    let percent: any;
    if (score !== undefined) {
        percent = (Number(value)*100);
    }

    React.useEffect(()=>{
        let copyIndicator = "";
        let copyMetric = "";
        switch(label){
            case "Savings_Water_Relative": {
                copyIndicator = "waterUse";
                copyMetric = "lH2O"
            } break;
            case "Savings_ClimateChange_Relative": {
                copyIndicator = "climateChange";
                copyMetric = "kgCO2";
            } break;
            case "Savings_Eutrophication_Relative": {
                copyIndicator = "eutrophication";
                copyMetric = "gPO4";
            } break;
            case "Savings_FossilFuels_Relative": {
                copyIndicator = "fossilFuels";
                copyMetric = "mj";
            } break;
        }
        setIndicator(copyIndicator);
        setMetric(copyMetric);
    },[label])

    const toggleValue = () => {
        return `${percent === null || percent === undefined ? '-- ' : formatNumberNoDecimals(Number(percent))}%`
    }

    return (
        <div className="d-flex flex-row justify-content-between align-items-center mt-2 mb-2">
            <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-row align-items-center progress-bar-bg">
                        <span className='text-dark-60' dangerouslySetInnerHTML={{ __html: t(`${label}-qr-value`, { value: toggleValue() }) }} />
                </div>
                <div className='d-flex flex-row align-items-center'>
                    <span className="text-dark-80 font-sfpro-regular letter-spacing-normal">{t(`${label}-qr-description`)}</span>
                    <SmartLabelTooltip label={label} text={t(`${label}-qr-tooltip`)} />
                </div>
            </div>

            <div className="d-flex justify-content-center" style={{width: '20px'}}>
                <span className={`${icon}`} style={{color: '#2C2C51', fontSize: '22px'}}></span>
            </div>
        </div>
    )

}