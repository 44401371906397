import React, { useState, useRef } from "react"
import { ViewDropDown } from 'UI/atoms/DropDown';
import { Popover, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import "./SelectorViews.scss"
import { useTable } from 'contexts/tableContext';
import useOutsideClick from "hooks/useOutsideClick.hook";
import { useProjects } from "contexts/projectsContext";

import { handleTitle } from "utils/utils";

const SelectorViews = () => {
    const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
    const { selectedView } = useTable();
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [isShowHover, setIsShowHover] = useState(false);
    const { t } = useTranslation();
    const ref = useRef<HTMLHeadingElement>(null);

    useOutsideClick(ref, () => { setIsShowDropdown(false) });

    const manageIsShowHover = () => isShowDropdown ? setIsShowHover(false) : setIsShowHover(true)

    const handleShowDropdown = () => {
        if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
            setIsShowDropdown(!isShowDropdown)
        }
    }

    const nameViewSelected = ["articleList", "suppliersList", "materialsList", "logisticList", "projectList", "overview-header"]
    return (
        <div ref={ref}>
            <div >
                <div className="preset-view-selector font-sfpro-regular" onClick={handleShowDropdown}>
                    <div className="d-flex flex-row nav-link cursor-pointer align-items-center position-relative" onMouseEnter={manageIsShowHover} onMouseLeave={() => setIsShowHover(false)}>
                        <div className="text-dark-100 font-sfpro-regular font-16px text-overflow-ellipsis overflow-hidden" style={{maxWidth: '190px'}}>
                            {t(handleTitle(selectedView))}
                        </div>
                        {
                            !nameViewSelected.includes(selectedView.name) &&
                            <>
                                <div
                                    className={`text-dark-60 text-dark-100-hover ml-1 d-flex justify-content-center align-items-center rounded-sm ${isShowDropdown ? "bg-gray-01 text-dark-100" : ""}`}
                                    style={{ height: '30px', width: '30px' }}
                                >
                                    <span
                                        id='arrengeViews'
                                        onClick={handleShowDropdown}
                                        className={`icon-icon-views mx-2 font-20px`}
                                        onMouseEnter={manageIsShowHover} onMouseLeave={() => setIsShowHover(false)}
                                    />
                                </div>
                                {isShowDropdown && <ViewDropDown />}
                                <Tooltip innerClassName="tooltips" arrowClassName="arrow-tooltip" placement="bottom-start" isOpen={isShowHover} target="arrengeViews" >
                                    {t("arrangeViews")}
                                </Tooltip>
                            </>
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}


export default SelectorViews;