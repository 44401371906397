import React, { useEffect, useRef, useState } from 'react';
import './Searchbar.scss';
import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { SimpleTooltip } from '../Tooltips/Index';
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
	const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const { searchText, setSearchText } = useTable();
	const [isShow, setIsShow] = useState(false);
	const ref = useRef<HTMLFormElement>(null);
	const { t } = useTranslation();
	useOutsideClick(ref, () => {
		!searchText && setIsShow(false);
	});
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (setSearchText) setSearchText(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	}

	const restartSearch = () => {
		if (setSearchText) setSearchText('');
	};

	const searchClick = () => {
		if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
			setIsShow(!isShow);
			restartSearch();
		}
	};

	useEffect(() => {
		if (searchText) setIsShow(true);
	}, []);

	return (
		<>
			<form
				onSubmit={handleSubmit}
				ref={ref}
				className={
					isShow
						? 'search-bar-active d-flex flex-row-reverse align-items-center'
						: 'search-bar d-flex flex-row-reverse align-items-center'
				}
			>
				<div
					id="table-search-bar"
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
					onClick={searchClick}
					className="d-flex align-items-center text-dark-60 text-dark-100-hover"
				>
					<span
						className={`icon-icon-${
							isShow ? 'close font-1px' : 'search font-20px'
						}`}
						style={{ margin: '6px' }}
					></span>
				</div>

				{isShow && (
					<input
						className="search-input font-sfpro-regular"
						type="text"
						placeholder="Search articles..."
						value={searchText}
						onChange={handleSelect}
					/>
				)}
			</form>
			<SimpleTooltip
				tooltipId="table-search-bar"
				tooltipOpen={isTooltipOpen}
				text={t('article-finder')}
			/>
		</>
	);
};

export default SearchBar;
