/* eslint-disable */
import React from "react";
import { useProjects } from "contexts/projectsContext";
import TableMetrics from "UI/Template/Table";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { useMenuState } from "hooks/useMenuState.hook";
import { SpinnerLoader } from "components/Common/Loader/Loader";

const SmartLabelsList = () => {
	const { projectSelected } = useProjects();
	const projectId = projectSelected?.id;
	const { callToAction, loading } = useMenuState('labeling')

	if (!projectId) {
		return (
			<InformationNavigation
				keyName="smart-label"
			/>
		)
	}

	if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

	return callToAction ? <InformationNavigation keyName="smart-label-upgrade" /> : <TableMetrics />
};

export default SmartLabelsList;
