/* eslint-disable */
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { SpinnerLoader } from "components/Common/Loader/Loader";
import { useMenuState } from "hooks/useMenuState.hook";
import React from "react";
import { useTranslation } from "react-i18next";

export const EcommerceIntegration = (props: any) => {
	const { t } = useTranslation();

	const { callToAction, loading } = useMenuState("integration");

	const images = [
		{
			src: "../img/integration-skfk.png",
			alt: "integration-skfk",
		},
		{
			src: "../img/integration-ecoalf.png",
			alt: "integration-ecoalf",
		},
		{
			src: "../img/integration-hoff.png",
			alt: "integration-hoff",
		},
		{
			src: "../img/integration-mu.png",
			alt: "integration-mu",
		},
	];

	interface ImgDiv {
		src: string;
		alt: string;
	}

	const ImgDiv = ({ src, alt }: ImgDiv) => {
		return (
			<div className="py-3 px-2">
				<img src={src} alt={alt} style={{ width: "230px" }}></img>
			</div>
		);
	};

	if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

	return callToAction ? <InformationNavigation keyName="integration-upgrade" />
		: <div
			className="d-flex flex-column h-100 rounded-10 px-1"
			style={{ paddingBottom: "10px" }}
		>
			<div
				className="d-flex flex-column flex-grow-1 bg-white-100 rounded-10 overflow-auto"
				style={{
					boxShadow: "0px 6px 6px rgba(214, 211, 211, 0.4)",
				}}
			>
				<div
					className="m-3 rounded-10 p-4"
					style={{ background: "#FBFBFC", minHeight: "382px" }}
				>
					<p className="font-20px text-dark-100 font-sfpro-medium">
						{t("integration-title")}
					</p>
					<p className="font-16px text-dark-40 mb-4">
						{t("integration-subtitle")}
					</p>
					<div className="d-flex justify-content-center">
						{images.map((img) => (
							<ImgDiv src={img.src} alt={img.alt} />
						))}
					</div>
				</div>

				<div
					className="mx-3 mb-3 rounded-10 p-4 d-flex flex-column justify-content-between flex-grow-1"
					style={{ background: "#FBFBFC", minHeight: "348px" }}
				>
					<div>
						<p className="font-16px font-sfpro-medium text-dark-60">
							{t("integration-steps-follow")}
						</p>
						<div className="font-16px text-dark-60 mt-4">
							<p className="my-3">
								{t("integration-step1-pre")}
								<a
									href="https://bcome.biz/help-center/tutorials/how-to-integrate-the-e-commerce-in-your-website/"
									className="font-sfpro-regular letter-spacing-normal text-yellow text-decoration-color-yellow mt-5 pl-1"
									target="_blank"
								>
									<span className="text-yellow">
										{t("integration-step-by-step")}
									</span>
								</a>
								{t("integration-step1-post")}
							</p>
							<p className="my-3">{t("integration-step2")}</p>
							<p className="my-3">{t("integration-step3")}</p>
						</div>
					</div>
					<p className="font-16px text-dark-60 mb-5">
						{t("integration-support-pre")}
						<a
							href="mailto: info@bcome.biz"
							className="font-sfpro-regular letter-spacing-normal text-dark-100 text-underlined mt-5 pl-1"
						>
							{t("contact-us")}
						</a>
						{t("integration-support-post")}
					</p>
				</div>
			</div>
		</div>
};

export default EcommerceIntegration;
