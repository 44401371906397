import { useProjects } from "contexts/projectsContext";
import { useCallback } from "react";

const ARTICLE = 'ARTICLE';

export const useDisableCEI = () => {
    const { allProjects, projectSelected } = useProjects();
    const disabled = useCallback(() => projectSelected?.referenceType !== ARTICLE, [projectSelected]);

    const disabledCeiSidebar = () => allProjects?.filter((project: any) => project.referenceType === ARTICLE).length === 0;

    return {
        allProjects,
        projectSelected,
        disabled: disabled(),
        disabledCeiSidebar
    }
}