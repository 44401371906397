import React, { useEffect, useState } from 'react';
import { useProjects } from 'contexts/projectsContext';
import { useHistory } from 'react-router-dom';
import path from 'permissions/roles/AppPaths';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { useTranslation } from 'react-i18next';
import { useTable } from 'contexts/tableContext';
import { formatDate, validateReferenceType } from 'utils/utils';

import { GenericPdfDownloader } from 'UI/molecules/OnePageHeader/Index';
import { downloadFileName } from '../../../constants';

const CEI = 'cei';


export const TableDashboardSelectorDisabled = () => {
	return (
		<div
			className="d-flex align-items-center text-dark-40"
			style={{ height: '40px' }}
		>
			<span className="icon-icon-dashboard font-20px" />
			<span
				className="mx-3"
				style={{ borderLeft: '1px solid #C6C7C8', height: '40px' }}
			/>
			<div
				className={
					'd-flex align-items-center justify-content-center cursor-pointer rounded-08 text-dark-100-hover bg-gray-01 text-dark-100'
				}
				style={{ height: '32px', width: '32px' }}
			>
				<span className={`icon-icon-table font-20px`} />
			</div>
		</div>
	);
};

const TableDashboardSelector = ({ generalProduct }: { generalProduct: string }) => {
	const h = useHistory();
	const { getSelectedView, handleLoading } = useTable()
	const { isDashboard, setIsDashboard, projectSelected, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const wrapComunStyle = 'd-flex align-items-center justify-content-center cursor-pointer rounded-08 text-dark-60 text-dark-100-hover';

	const blockedStyles = 'd-flex align-items-center justify-content-center rounded-08 block-dashboard';

	const benchmarkStyles = 'd-flex align-items-center justify-content-center rounded-08 text-blue-metal-30'

	const lineStyle = { borderLeft: '1px solid #C6C7C8', height: '40px' };
	const p = h.location.pathname.split('/')[2];
	const generalScope = h.location.pathname.split('/')[3];
	const { t } = useTranslation();

	const formatedActualDate = formatDate();
	const projectSelectedName = projectSelected?.name;
	const textDownloadFile = downloadFileName[generalProduct];

	const buildedFileName = `BCome ${textDownloadFile} Dashboard - ${projectSelectedName} - ${formatedActualDate}`;

	useEffect(() => {
		if (!h.location.pathname.includes('dashboard')) {
			setIsDashboard(false);
		} else {
			setIsDashboard(true);
			if (p === 'lca') {
				h.push(
					path.metricsLcaDashboard.replace(
						':projectId',
						projectSelected?.id
					)
				);
			}
			if (p === 'sustainabilityindex') {
				h.push(
					path.evaluationSiDashboard.replace(
						':projectId',
						projectSelected?.id
					)
				);
			}
			if (p === 'plm') {
				h.push(
					path.traceabilityDashboard
				)
			}
		}
	}, [h.location.pathname, projectSelected]);

	// Display table and set route for table icon click depending on product selected
	const handleIconTable = () => {
		if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
			setIsDashboard(false);
			if (p === 'lca') {
				h.push(path.metricsLcaArticles);
			}
			if (p === 'sustainabilityindex') {
				h.push(path.evaluationSiArticles);
			}
			if (p === 'benchmark') {
				h.push(path.metricsBenchmarkArticles)
			}
			if (p === 'plm') {
				let scope = 'article'
				if (validateReferenceType(projectSelected)) {
					scope = 'material'
				}
				if (!generalScope.includes(scope)) {
					getSelectedView(scope)
					handleLoading(true)
					h.push(scope === 'article' ? path.traceability : path.traceabilityMaterials)
				}
			}
			if (p === 'cei') {
				h.push(path.cei);
			}
		}
	};

	// Display dashboard and set route for dashboard icon click depending on product selected
	const handleIconDashboard = () => {
		if (p === "benchmark") {
			setIsDashboard(false)
		} else {
			if( openModalSaveChanges.customView ){
				setOpenModalSaveChanges({
					...openModalSaveChanges,
					open: true
				})
			}else{
				setIsDashboard(true);
				if (p === 'lca') {
					h.push(
						path.metricsLcaDashboard.replace(
							':projectId',
							projectSelected?.id
						)
					);
				}
				if (p === 'sustainabilityindex') {
					h.push(
						path.evaluationSiDashboard.replace(
							':projectId',
							projectSelected?.id
						)
					);
				}
				if (p === 'benchmark') {
					h.push(
						path.metricsBenchmarkArticles
					);
				}

				if (p === 'plm') {
					h.push(
						path.traceabilityDashboard
					)
				}

				if (p === 'cei') {
					h.push(path.ceiDashboard);
				}
			}
		}
	};

	const IconComponent = ({
		icon,
		stylesClass,
		onClick,
	}: {
		icon: string;
		stylesClass: string;
		onClick: () => void;
	}) => {
		const [isShowTooltip, setIsShowTooltip] = useState(false);
		const divId = `select-${icon}`;

		const handleDashboardIconStyles = () => {

			if (icon === "dashboard") {
				if (p === "benchmark") return benchmarkStyles
			}

			return wrapComunStyle
		}

		return (
			<>
				<div
					id={divId}
					onMouseEnter={() => setIsShowTooltip(true)}
					onMouseLeave={() => setIsShowTooltip(false)}
					className={`${handleDashboardIconStyles()}${stylesClass}`}
					style={{ height: '32px', width: '32px' }}
				>
					<span
						onClick={onClick}
						className={`icon-icon-${icon} font-20px`}
					/>
				</div>
				{
					!(icon === "dashboard" && p === "benchmark") &&
					<SimpleTooltip
						tooltipId={divId}
						tooltipOpen={isShowTooltip}
						text={t(`select-${icon}`)}
					/>
				}
			</>
		);
	};

	return (
		<div className="d-flex align-items-center" style={{ height: '40px' }}>
			<div className='d-flex flex-row align-items-center bg-gray-00 rounded-08 p-1'>
				<IconComponent
					stylesClass={isDashboard ? ' bg-gray-01 text-dark-100' : ''}
					icon="dashboard"
					onClick={handleIconDashboard}
				/>
				<IconComponent
					stylesClass={!isDashboard ? ' bg-gray-01 text-dark-100' : ''}
					icon="table"
					onClick={handleIconTable}
				/>
			</div>
			<span className="mx-2" style={lineStyle} />
			<GenericPdfDownloader
				rootElementId={`${generalProduct}-dashboard`}
				downloadFileName={buildedFileName}
				tableDownload={!isDashboard}
				disabled={p === CEI}
			/>
		</div>
	);
};

export default TableDashboardSelector;
