import { SpinnerLoader } from "components/Common/Loader/Loader";
import { useMenuState } from "hooks/useMenuState.hook";
import React from "react";
import { InformationNavigation } from "UI/molecules/InformationNavigation";

import TableMetrics from "UI/Template/Table";

export const DataCollection = () => {

    const { callToAction, loading } = useMenuState('data_collection')

    if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

    return (
        callToAction ? <InformationNavigation keyName="data-collection-upgrade" /> : <TableMetrics />
    )
}