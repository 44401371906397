import { EIndicators, ESmartlabels, EStages } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const smartlabelSchema: ITableColumns = {
    checklist: {
        indicator: EIndicators.checklist,
        stage: EStages.checklist,
        label: 'checklist',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.checklist)
    },
    views: {
        indicator: ESmartlabels.views,
        stage: EStages.total,
        label: 'views',
        color: '#1C1C30',
        columns: columnsSchema.filter(x => x.indicator === ESmartlabels.views)
    },
    smartlabels: {
        indicator: ESmartlabels.smartlabels,
        stage: EStages.total,
        label: 'smartlabels',
        color: '#1C1C30',
        columns: columnsSchema.filter(x => x.indicator === ESmartlabels.smartlabels)
    },
    qrcode: {
        indicator: ESmartlabels.qrcode,
        stage: EStages.total,
        label: 'qrcode',
        color: '#1C1C30',
        columns: columnsSchema.filter(x => x.indicator === ESmartlabels.qrcode)
    },
}

export default smartlabelSchema;