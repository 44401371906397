import { EIndicators, EStages, ESustainibilityIndex } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const sustainabilityindexSchema: ITableColumns = {
    checklist: {
        indicator: EIndicators.checklist,
        stage: EStages.checklist,
        label: 'checklist',
        columns: columnsSchema.filter(x => x.indicator === ESustainibilityIndex.checklist)
    },
    planet: {
        indicator: ESustainibilityIndex.planet,
        stage: ESustainibilityIndex.planet,
        label: 'planet',
        color: '#773F0F',
        columns: columnsSchema.filter(x => x.indicator === ESustainibilityIndex.planet)
    },
    people: {
        indicator: ESustainibilityIndex.people,
        stage: ESustainibilityIndex.people,
        label: 'people',
        color: '#0E343C',
        columns: columnsSchema.filter(x => x.indicator === ESustainibilityIndex.people)
    },
    transparency: {
        indicator: ESustainibilityIndex.transparency,
        stage: ESustainibilityIndex.transparency,
        label: 'transparency',
        color: '#BE8F1F',
        columns: columnsSchema.filter(x => x.indicator === ESustainibilityIndex.transparency)
    },
    circularity: {
        indicator: ESustainibilityIndex.circularity,
        stage: ESustainibilityIndex.circularity,
        label: 'circularity',
        color: '#C8B7AF',
        columns: columnsSchema.filter(x => x.indicator === ESustainibilityIndex.circularity)
    }
}

export default sustainabilityindexSchema;