import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProjects } from 'contexts/projectsContext';
import DashboardHttp from 'services/http/Dashboards';

import Notification from 'components/Elements/Notification';
import { ICrudListResponse } from 'services/http/Crud';

import {
	TTransportDistribution,
	TStaticValues,
	TraceabilitySummary,
	TTraceabilityCompletion,
	TManufacturingCountries,
	TMaterialCountries,
	TTraceabilityRanking,
	TContent,
	TraceabilityCompletion
} from 'types/TTraceabilityDashboard';
import { isArray } from 'lodash';

export const useTraceabilityDashboard = (): any => {
	const { t } = useTranslation();

	const summaryKeys: string [] = ['project_articles', 'project_materials', 'project_suppliers', 'project_distance']
	const [summaryCards, setSummaryCards] = useState<TraceabilitySummary>({
		project_articles: '0',
		project_materials: '0',
		project_suppliers: '0',
		project_distance: 0
	});
	const [completion, setCompletion] = useState<TraceabilityCompletion>({
		original: [],
		tooltips: undefined
	});
	const [transportDistribution, setTransportDistribution] = useState<
		TStaticValues[]
	>([]);
	const [rawMaterialComposition, setRawmaterialComposition] = useState<
		TStaticValues[]
	>([]);
	const [
		manufacturingCountries,
		setManufacturingCountries,
	] = useState<TManufacturingCountries>({
		project_article_supplier_manufacturing_origins: [],
		project_article_supplier_manufacturing_origin_shares: []
	});
	const [
		materialCountries,
		setMaterialCountries,
	] = useState<TMaterialCountries>({
		project_article_process_matProcessing_supplier_origin: [],
		project_article_process_matProcessing_supplier_origin_shares: []
	});
	const [
		traceabilityRanking,
		setTraceabilityRanking,
	] = useState<TTraceabilityRanking>({
		project_article_traceability_score_ranking_best: [],
		project_article_traceability_score_ranking_worst: []
	});
	const [
		rawMaterialCharacteristics,
		setRawMaterialCharacteristics,
	] = useState<TContent[]>([]);

	const [loading, setLoading] = useState<boolean>(true);
	const { projectSelected } = useProjects();
	const Dashboard = new DashboardHttp();

	const SUMMARY_TITLES = [
		t('scopes-declared', { scope: t('sidebar-nav-articles') }),
		t('scopes-declared', { scope: t('sidebar-nav-materials') }),
		t('scopes-declared', { scope: t('sidebar-nav-suppliers') }),
		t('average-distance'),
	];

	const COMPLETION_PIE_COLORS: TStaticValues[] = [
		{ name: 'suppliers-unknown', color: '#F0F0F2', value: 0 },
		{ name: 'suppliers-known', color: '#497ACF', value: 0 },
	];
	const TRANSPORT_USED: TStaticValues[] = [
		{ name: 'traceability-ship', color: '#927F3F', value: 0 },
		{ name: 'traceability-plane', color: '#CD90B3', value: 0 },
		{ name: 'traceability-train', color: '#E4AF4C', value: 0 },
		{ name: 'traceability-truck', color: '#DE6E56', value: 0 },
		{ name: 'unknown', color: '#F0F0F2', value: 0 },
		{ name: 'not-applicable', color: '#C6C7C8', value: 0}
	];

	const RAW_MATERIAL_COMPOSITION: TStaticValues[] = [
		{ name: 'traceability-animal', color: '#CD90B3', value: 0 },
		{ name: 'traceability-artificial', color: '#8B7747', value: 0 },
		{ name: 'traceability-vegetal', color: '#E4AF4C', value: 0 },
		{ name: 'traceability-non-organic', color: '#DE6E56', value: 0 },
		{ name: 'traceability-polymer', color: '#728081', value: 0 },
		{ name: 'unknown', color: '#F0F0F2', value: 0 },
		{ name: 'undefined', color: '#5A2320', value: 0 }
	];

	const validateData = (value: any, name: string) =>
		value?.config.url.includes(name);

	useEffect(() => {
		const fetchAllData = async (id: any) => {
			setLoading(true);
			const data = await Promise.allSettled([
				Dashboard.traceabilitySummary(id),
				Dashboard.traceabilityCompletion(id),
				Dashboard.traceabilityDistributionTransportUsed(id),
				Dashboard.traceabilityManufacturingCountries(id),
				Dashboard.traceabilityMaterialCountries(id),
				Dashboard.traceabilityRanking(id),
				Dashboard.traceabilityRawMaterialComposition(id),
				Dashboard.traceabilityRawMaterialsCharacters(id),
			]);
			const response = data.filter(
				(res) => res.status === 'fulfilled'
			) as PromiseFulfilledResult<ICrudListResponse>[] | undefined;

			if (!response) {
				const error = (data.find((res) => res.status === 'rejected') as
					| PromiseRejectedResult
					| undefined)?.reason;
				const response = error.response?.data ?? {
					message: 'An error ocurred',
				};
				Notification.display('error', response.message);
				return;
			}

			response.forEach(({ value }) => {
				if (validateData(value, 'traceabilitySummary')) {
					setSummaryCards(value.data as TraceabilitySummary);
				}
				if (validateData(value, 'traceabilityCompletion')) {
					const totalValue = Object.values(value.data)[1] as number;
					const restValue = 100 - totalValue;

					COMPLETION_PIE_COLORS[0].value = restValue;
					COMPLETION_PIE_COLORS[1].value = totalValue;
					// order by value
					COMPLETION_PIE_COLORS.sort((a: TStaticValues, b: TStaticValues) => b.value - a.value)
					setCompletion({
						original: COMPLETION_PIE_COLORS,
						tooltips: value.data
					})
				}
				if (validateData(value, 'transportDistribution')) {
					Object.values(value.data).forEach(
						(v: any, index: number) => {
							TRANSPORT_USED[index].value = v;
						}
					);

					setTransportDistribution(
						TRANSPORT_USED.filter(
							(item: TStaticValues) => item.value > 0
						).sort(
							(a: TStaticValues, b: TStaticValues) =>
								b.value - a.value
						)
					);
				}
				if (validateData(value, 'manufacturingCountries')) {
					setManufacturingCountries(
						value.data as TManufacturingCountries
					);
				}
				if (validateData(value, 'materialCountries')) {
					setMaterialCountries(value.data as TMaterialCountries);
				}
				if (validateData(value, 'queryForTraceabilityRanking')) {
					setTraceabilityRanking(value.data as TTraceabilityRanking);
				}
				if (validateData(value, 'queryRawMaterialsCharacters')) {
					const values = Object.keys(value.data).map((key: string) => value.data[key]);
					const rawMaterialCharactersSortedArray =
					values
					.sort((a: TContent, b: TContent) => b.value - a.value)
					.map((item: TContent) => item);

					setRawMaterialCharacteristics(
						rawMaterialCharactersSortedArray
					);
				}
				if (validateData(value, 'rawMaterialCompositionDistribution')) {
					Object.values(value.data).forEach(
						(v: any, index: number) => {
							RAW_MATERIAL_COMPOSITION[index].value = v;
						}
					);
					setRawmaterialComposition(
						RAW_MATERIAL_COMPOSITION.filter(
							(item: TStaticValues) => item.value > 0
						).sort(
							(a: TStaticValues, b: TStaticValues) =>
								b.value - a.value
						)
					);
				}
			});

			setLoading(false);
		};

		const id = projectSelected?.id;
		if (id) {
			fetchAllData(id);
		}
	}, [projectSelected]);

	const detectEmptyObject = (
		object:
			| TTransportDistribution
			| TTraceabilityRanking
			| TTraceabilityCompletion
			| TManufacturingCountries
			| TMaterialCountries
			| TraceabilitySummary
			| TContent
			| TStaticValues[]
			| undefined
	) =>  isArray(object) ? object.length === 0 : (Object.keys(object ?? {}).length === 0 || Object.values(object ?? {})[0].length === 0)

	const toggleCompletionTooltips = (
		parameter: 'project_traceability_score' | 'project_traceability_total_suppliers_traced' | 'project_traceability_rmat_suppliers_traced' | 'project_traceability_mat_suppliers_traced' | 'project_traceability_article_suppliers_traced',
		returnedValue: number
	) =>
		completion.tooltips ? parseFloat(completion.tooltips[parameter]) : returnedValue

	return {
		t,
		summaryCards,
		completion,
		transportDistribution,
		rawMaterialComposition,
		manufacturingCountries,
		materialCountries,
		traceabilityRanking,
		rawMaterialCharacteristics,
		loading,
		detectEmptyObject,
		SUMMARY_TITLES,
		COMPLETION_PIE_COLORS,
		TRANSPORT_USED,
		RAW_MATERIAL_COMPOSITION,
		toggleCompletionTooltips,
		summaryKeys
	};
};
