import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useProjects } from "contexts/projectsContext";
import { useAccount } from "contexts/accountContext";
import ProjectHttp from 'services/http/Project';
import DashboardHttp from "services/http/Dashboards";
import { ICrudListResponse } from "services/http/Crud";
import Hubspot from 'services/http/Hubspot';

const formId = process.env.REACT_APP_HUBSPOT_FORM_CUSTOM_DATASET;

const useHome = () => {
    const [loading, setLoading] = useState(true);
    const Dashboard = new DashboardHttp();
    const Project = new ProjectHttp();
    const { t } = useTranslation();
    const { account, user } = useAccount();
    const { allProjects, setProjectSelected } = useProjects();
    const [customDataset, setCustomDataset] = useState(0);
    const customStylePlanStatus = {
		width: '12px',
		height: '12px',
		borderRadius: '50%',
		background: "#001D6C"
	}

	const handleDates = (date: string, updatedMonth?: number) => {
		function join(t: Date, a: any[], s: string) {
			function format(m: any) {
				const f = new Intl.DateTimeFormat('en', m);
				return f.format(t);
			}
			return a.map(format).join(s);
		}

		const a = [{ day: 'numeric' }, { month: 'long' }, { year: 'numeric' }];
		const realDate = new Date(date);

		if (updatedMonth) {
			realDate.setMonth(realDate.getMonth() + updatedMonth);
		}

		const result = join(realDate, a, ' ');

		return result;
	};

    const handleClasses: any = (projects: any[]) => {
		return projects && projects.length > 0 ? {maxHeight: '347px', minHeight: '347px', overflowY: "auto"} : { height: "48.5%", minHeight: '347px' }
	}

    const onGetItems = useCallback(async (values: Record<string, unknown>, pagination: Record<string, unknown>, concat = false, forceUpdate = false) => {
		if (allProjects) {
				const request: Promise<ICrudListResponse> = Project.allProjects();
			return await request;
		}
	}, [allProjects]);

    const handleSubmitDatasets = (e: any) => {
		e.preventDefault();

        if (formId) {
            new Hubspot().submit(user.email, t("custom-dataset-notification-success"), t("wait-list-notification-error"), formId)
        }
	}

    useEffect(() => {
        const getCustomDatasets = async() => {
            if (account) {
                await Dashboard.getCustomDataset(account?.id)
                .then(response => {
                    setCustomDataset(response.data.total_custom)
                })
                .catch(error => console.log(error))
            }
        };

        getCustomDatasets();
    }, [account])

    useEffect(() => {
		setLoading(false);
	});

    return {
        customStylePlanStatus,
        handleDates,
        handleClasses,
        onGetItems,
        allProjects,
        setProjectSelected,
        loading,
        handleSubmitDatasets,
        t,
        account,
        user,
        customDataset
    }
};

export default useHome;
