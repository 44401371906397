import React from 'react';
import { Link } from 'react-router-dom';

import { ArticleImgSmall } from './../../atoms/ArticleImg/index'
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';

interface Props {
    id: string,
    name: string,
    reference: string,
    imageUrl: string | undefined | null;
    styles?: string | undefined,
    path?: string | undefined,
}

// ROW ARTICLE IMAGE
const ArticleDetail = (props:Props) => {
    const { name, reference, imageUrl, id, styles, path} = props;
    const pathOnePage = path && !path.includes('data-collection') ? "/articles/" + id : '#';
    const tooltip_id = "B" + id.replace(/[^A-Z0-9]/ig, "_");
    const tooltipId = tooltip_id;
    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

    const handleMouseAction = (e: any, value: boolean) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setIsTooltipOpen(value);
        }
    }

    return (
        <div className={`d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden ${styles ? styles : ''}`}>
            {
                imageUrl &&
                <Link to={pathOnePage} className="text-decoration-none">
                    <ArticleImgSmall imgUrl={imageUrl} />
                </Link>
            }
            <Link to={pathOnePage} className="text-decoration-none text-overflow-ellipsis overflow-hidden">
                <div className={`d-flex flex-column text-overflow-ellipsis overflow-hidden ${imageUrl ? "ml-2" : ""} mr-2`} style={{ lineHeight: 1.6 }}>
                    <span
                        id={tooltipId}
                        onMouseEnter={(e) => handleMouseAction(e,true)}
                        onMouseLeave={(e) => handleMouseAction(e,false)}
                        className={`${imageUrl ? "pl-2" : ""} font-sfpro-medium letter-spacing-normal text-capitalize font-14px text-overflow-ellipsis overflow-hidden`}
                        style={{ lineHeight: 1.6 }}
                    >{name}</span>
                    {
                        reference && <span className="pl-2 font-sfpro-regular letter-spacing-normal text-uppercase text-dark-60 font-12px text-overflow-ellipsis overflow-hidden" style={{ lineHeight: 1.6 }}>{reference}</span>
                    }
                </div>
            </Link>
            <SimpleTooltip
                tooltipId={tooltipId}
                tooltipOpen={isTooltipOpen}
                text={name}
                styles={{ maxWidth: '250px', textAlign: 'start' }}
            />
        </div>
   );
}

{/* <ArticleDetail name={} reference={} imageUrl={} i={} /> */}

export default ArticleDetail;
