import React from "react";
import { useTranslation } from 'react-i18next';

function RightsReserved() {
    const { t } = useTranslation();

    const url = 'https://bcome.biz/privacy-policy/'
    const actualYear = new Date().getFullYear();

    return (
        <div className='pt-3 fixed-bottom p-5 font-sfpro-regular text-dark-60 font-12px'>
            <div className='text-center pb-1'>{t('bcome-rights-reserved', { year: actualYear })}</div>
            <div className='text-center'>
                <a className="text-overflow-ellipsis overflow-hidden text-yellow text-decoration-color-yellow" target="_blank" href={url}>
                    <span className="text-yellow" style={{ margin: "0px 3px" }}>{t(`privacy-policy`)}</span>
                    <span className="icon-icon-new-window m-1 text-yellow"></span>
                </a>
            </div>
        </div>
    )
}

export default RightsReserved;