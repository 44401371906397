import React from 'react';
import TableRow from "UI/molecules/TableRow/index"
import { isEven } from 'utils/utils';
import { useTable } from 'contexts/tableContext';


const TableBody = ({items}: {items: any[]}) => {
    const {columnsList, enableBenchmarkData, sortMetric, selectedView} = useTable();
    const striped = true;

    const handleSort = React.useCallback(() => {
        if (enableBenchmarkData && sortMetric.length > 0 && sortMetric[0].field) {
            return items?.sort((a:any, b:any) =>
                sortMetric[0].order === "DESC"
                ? b[`Savings_${sortMetric[0].field}_Relative`] - a[`Savings_${sortMetric[0].field}_Relative`]
                : a[`Savings_${sortMetric[0].field}_Relative`] - b[`Savings_${sortMetric[0].field}_Relative`]
            )
        }

        return items;
    }, [sortMetric, enableBenchmarkData, items])

    return(
        <tbody>
            {
                handleSort().map((a:any, i:number) =>
                    <TableRow
                        key={`${a.id}-${i}`}
                        indexRow={i}
                        datas={a}
                        subcolumns={columnsList}
                        striped={isEven(i) && striped}
                        enableBenchmarkData={enableBenchmarkData}
                        {...{selectedView}}
                    />
            )}
        </tbody>
    )
}

export default TableBody;
